<template>
  <section class="mt-2">


    <b-overlay :show="loading" rounded="sm">

      <h5 class="mt-3">{{ $t("DEFAULT_VALUE") }}</h5>
      <div class="mt-1">
        <b-form-select
          v-model="default_loa_level"
          :options="loaLevels"
        ></b-form-select>
      </div>

      <h5 class="mt-3">{{ $t("LOA.MESSAGE_LOA_ENABLED") }}</h5>
      <div class="mt-1">
        <b-form-checkbox switch v-model="loa1_enabled" name="check-button" :value="1" :unchecked-value="0"> {{ $t('LOA.LEVEL.1') }}</b-form-checkbox> 
      </div>
      <div class="mt-1">
        <b-form-checkbox switch v-model="loa2_enabled" name="check-button" :value="1" :unchecked-value="0"> {{ $t('LOA.LEVEL.2') }}</b-form-checkbox> 
      </div>
      <div class="mt-1">
        <b-form-checkbox switch v-model="loa3_enabled" name="check-button" :value="1" :unchecked-value="0"> {{ $t('LOA.LEVEL.3') }}</b-form-checkbox> 
      </div>

      <h5 class="mt-3">{{ $t("LOA.CHANGE_TEXT.1") }}</h5>
      <div class="mt-1">        
        <b-form-input trim
          required
          type="text"
          :placeholder="$t('LOA.1')"
          v-model="loa1_text"
          name="editLoa1Text"
        ></b-form-input>
      </div>

         

      <h5 class="mt-3">{{ $t("LOA.CHANGE_TEXT.2") }}</h5>
      <div class="mt-1">
        <b-form-input trim
          required
          type="text"
          :placeholder="$t('LOA.2')"
          v-model="loa2_text"
          name="editLoa2Text"
        ></b-form-input>
      </div>

      

      <h5 class="mt-3">{{ $t("LOA.CHANGE_TEXT.3") }}</h5>
      <div class="mt-1">
        <b-form-input trim
          required
          type="text"
          :placeholder="$t('LOA.3')"
          v-model="loa3_text"
          name="editLoa3Text"
        ></b-form-input>
      </div>
   

      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="editBtn">{{
        $t("SAVE")
      }}</b-button>

      <b-button
        variant="danger"
        v-if="user.information.organisation.id != information.id"
        class="btn-fill float-right"
        @click="removeBtn"
        >{{ $t("REMOVE") }}</b-button
      >
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      information: this.organisation,
      loading: false,
      show: false,
      loa1_text: "",
      loa2_text: "",
      loa3_text: "",
      loa1_enabled: 1,
      loa2_enabled: 1,
      loa3_enabled: 1,
      default_loa_level: 0,
      loaLevels: [
        { value: 0, text: this.$t("STATUS_INACTIVE") },
        { value: 1, text: this.$t("LOA.LEVEL.1") },
        { value: 2, text: this.$t("LOA.LEVEL.2") },
        { value: 3, text: this.$t("LOA.LEVEL.3") },
      ]
    };
  },
  methods: {
    editBtn: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(
          this.user.hostname + "/organisation/settings/loa",
          {
            organisation_id: this.information.id,
            default_loa_level: this.default_loa_level,
            loa1_text: this.loa1_text,
            loa2_text: this.loa2_text,
            loa3_text: this.loa3_text,
            loa1_enabled: this.loa1_enabled,
            loa2_enabled: this.loa2_enabled,
            loa3_enabled: this.loa3_enabled
          }
        )
        .then(function() {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
          self.show = false;
        })
        .catch(function() {
        });
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function() {
    this.loa1_text = this.information.loa1_text;
    this.loa2_text = this.information.loa2_text;
    this.loa3_text = this.information.loa3_text;
    this.loa1_enabled = this.information.loa1_enabled;
    this.loa2_enabled = this.information.loa2_enabled;
    this.loa3_enabled = this.information.loa3_enabled;
    this.default_loa_level = this.information.default_loa_level;
  },
};
</script>
<style></style>
